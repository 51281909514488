import { useTranslation } from "react-i18next";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import type { NewType } from "../../types/common";
import { New } from "../../components/news/new";
import { NavLink, useParams } from "react-router-dom";
import { Markdown } from "../../components/common/markdown";
import moment from "moment";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";

export function NewPage() {
	const { t, i18n } = useTranslation();

	const { id } = useParams();

	const { data: newData } = useStrapiFetch<NewType>(
		`/api/news/${id}?populate=image`
	);
	const { data: latestNewsData } = useStrapiFetch<NewType[]>(
		`/api/news?populate=image&sort=date:desc&${
			id ? `filters[id][$ne]=${id}&` : ""
		}pagination[pageSize]=3`
	);

	if (!newData || !latestNewsData) {
		return null;
	}

	const { titlePL, titleEN, bodyEN, bodyPL, date, image } = newData.attributes;
	const { url, alternativeText } = image.data.attributes;

	i18n.addResourceBundle(
		"pl",
		"translation",
		{ news: { [newData.id]: { title: titlePL, body: bodyPL } } },
		true,
		true
	);

	i18n.addResourceBundle(
		"en",
		"translation",
		{ news: { [newData.id]: { title: titleEN, body: bodyEN } } },
		true,
		true
	);

	latestNewsData.forEach((n) => {
		const { titlePL, titleEN } = n.attributes;

		i18n.addResourceBundle(
			"pl",
			"translation",
			{ news: { [n.id]: { title: titlePL } } },
			true,
			true
		);

		i18n.addResourceBundle(
			"en",
			"translation",
			{ news: { [n.id]: { title: titleEN } } },
			true,
			true
		);
	});

	return (
		<>
			<div className="w-full max-w-[1200px] mx-auto px-[15px] pt-7 md:pt-9 relative">
				<h1 className="font-semibold text-[#002969] text-xl sm:text-2xl md:text-3xl text-center">
					{t(`news.${newData.id}.title`)}
				</h1>
			</div>

			<main>
				<div className="relative w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9">
					<div className="flex flex-col md:flex-row items-start gap-6 md:gap-9">
						<div className="flex-1">
							<img
								src={getStrapiImageUrl(url)}
								alt={alternativeText}
								className="object-contain"
							/>

							<div className="opacity-50 text-sm mt-4">
								<time dateTime={date.toString()}>
									{moment(date).format("YYYY-MM-DD")}
								</time>
							</div>

							<Markdown className="mt-4">
								{t(`news.${newData.id}.body`)}
							</Markdown>
						</div>

						<div className="flex flex-row md:flex-col flex-wrap justify-center items-start gap-4 md:gap-6">
							{latestNewsData.map((n) => (
								<New key={`aktualnosci-${n.id}`} data={n} />
							))}
						</div>
					</div>

					<div className="text-red-600 hover:opacity-90 mt-9">
						<NavLink to="/aktualnosci">{t("news.goBack")}</NavLink>
					</div>
				</div>
			</main>
		</>
	);
}
