export function Accessability() {
	function toggleContrast() {
		document.body.classList.toggle("high-contrast");
	}

	function toggleFontSize() {
		document.body.classList.toggle("increased-font-size");
	}

	return (
		<div className="flex gap-2">
			<button
				onClick={() => toggleContrast()}
				className="a11y bg-[yellow] p-1 rounded-full w-10 h-10"
			>
				<img
					alt="Kontrast"
					title="Przełącz kontrast"
					src="/theme/assets/contrast.png"
					className="a11y"
				/>
			</button>
			<button
				onClick={() => toggleFontSize()}
				className="a11y bg-[yellow] p-1 rounded-full w-10 h-10"
			>
				<img
					alt="Rozmiar czcionki"
					title="Przełącz rozmiar czcionki"
					src="/theme/assets/font-size.png"
					className="a11y"
				/>
			</button>
		</div>
	);
}
