import { useTranslation } from "react-i18next";
import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import type { NewType } from "../../types/common";
import { New } from "../../components/news/new";

export function NewsPage() {
	const { t, i18n } = useTranslation();

	const { data: newsData } = useStrapiFetch<NewType[]>(
		"/api/news?populate=image&sort=date:desc"
	);

	if (!newsData) {
		return null;
	}

	newsData.forEach((n) => {
		const { titlePL, titleEN } = n.attributes;

		i18n.addResourceBundle(
			"pl",
			"translation",
			{ news: { [n.id]: { title: titlePL } } },
			true,
			true
		);

		i18n.addResourceBundle(
			"en",
			"translation",
			{ news: { [n.id]: { title: titleEN } } },
			true,
			true
		);
	});

	return (
		<>
			<div className="w-full max-w-[1200px] mx-auto px-[15px] pt-7 md:pt-9 relative">
				<h1 className="font-semibold text-[#002969] text-xl sm:text-2xl md:text-3xl text-center">
					{t("news.title")}
				</h1>
			</div>

			<main>
				<div className="relative w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9">
					<div className="flex flex-wrap justify-center gap-4 md:gap-8">
						{newsData.map((n) => (
							<New key={`aktualnosci-${n.id}`} data={n} />
						))}
					</div>
				</div>
			</main>
		</>
	);
}
