import { MapContainer, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import type { BrandType } from "../../types/common";
import { MapItems } from "./map-items";

function calcCenter(brands: BrandType[]): [number, number] {
	let mapCenter: [number, number] = [0, 0];
	let brandsCount = 0;

	for (const brand of brands) {
		if (!brand.active) {
			continue;
		}

		let brandCenter = [0, 0];
		let placesCount = 0;

		for (const bar of brand.places) {
			if (!bar.active || !bar.config.geo) {
				continue;
			}

			const { lat, lon } = bar.config.geo;
			brandCenter = [brandCenter[0] + lat, brandCenter[1] + lon];
			placesCount++;
		}

		brandCenter = [brandCenter[0] / placesCount, brandCenter[1] / placesCount];

		mapCenter = [mapCenter[0] + brandCenter[0], mapCenter[1] + brandCenter[1]];
		brandsCount++;
	}

	return [mapCenter[0] / brandsCount, mapCenter[1] / brandsCount];
}

export function Map({ data }: { data?: BrandType[] }) {
	if (!data) {
		return null;
	}

	return (
		<div id="mapa">
			<MapContainer
				center={calcCenter(data)}
				zoom={6}
				scrollWheelZoom={false}
				className="w-screen min-h-[50vh] h-80"
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<MapItems data={data} />
			</MapContainer>
		</div>
	);
}
