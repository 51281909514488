import { Error } from "./components/error/error";
import { useAppErrorStore } from "./hooks/use-app-error-store";
import { Route, Routes } from "react-router-dom";
import { NewsPage } from "./pages/news-page/news-page";
import { MainPage } from "./pages/main-page/main-page";
import { NewPage } from "./pages/news-page/new-page";
import { NotFoundPage } from "./pages/not-found-page/not-found-page";
import { useAppLoadingStore } from "./hooks/use-app-loading-store";
import { Loader } from "./components/loader/loader";
import { Header } from "./components/header/header";
import { Footer } from "./components/footer/footer";
import { SubsidesPage } from "./pages/subsidies-page/subsidies-page";

export default function App() {
	const { isError } = useAppErrorStore();
	const { tags } = useAppLoadingStore();

	const isLoading = tags.length > 0;

	return (
		<div
			id="start"
			className={`font-['Asap'] flex flex-col min-h-dvh overflow-hidden ${
				isError ? "h-screen overflow-hidden" : ""
			}`}
		>
			{isError && <Error />}

			{isLoading && <Loader />}

			<Header />

			<div className="flex-1">
				<Routes>
					<Route path="" element={<MainPage />} />
					<Route path="/aktualnosci" element={<NewsPage />} />
					<Route path="/aktualnosci/:id?" element={<NewPage />} />
					<Route path="/dotacje" element={<SubsidesPage />} />
					<Route path="/*" element={<NotFoundPage />} />
				</Routes>
			</div>

			<Footer />
		</div>
	);
}
