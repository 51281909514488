import { useMap } from "react-leaflet";
import type { BrandType } from "../../types/common";
import { MapPlace } from "./map-place";
import useSupercluster from "use-supercluster";
import { MapCluster } from "./map-cluster";
import { useEffect, useState } from "react";
import { LeafletEvent } from "leaflet";

export function MapItems({ data }: { data: BrandType[] }) {
	const map = useMap();
	const [bounds, setBounds] = useState(map.getBounds());
	const [zoom, setZoom] = useState(map.getZoom());

	useEffect(() => {
		function onMoveEnd(e: LeafletEvent) {
			setBounds(e.target.getBounds());
		}
		map.on("moveend", onMoveEnd);

		function onZoomEnd(e: LeafletEvent) {
			setZoom(e.target.getZoom());
		}
		map.on("zoomend", onZoomEnd);

		return () => {
			map.off("moveend", onMoveEnd);
			map.off("zoomend", onZoomEnd);
		};
	}, [map, setBounds, setZoom]);

	const points = data.flatMap((brand) => {
		return brand.places
			.filter((place) => place.active && place.config.geo)
			.map((place) => {
				return {
					type: "Feature" as const,
					properties: { cluster: false, point_count: 0, place, brand },
					geometry: {
						type: "Point" as const,
						coordinates: [place.config.geo.lon, place.config.geo.lat],
					},
				};
			});
	});

	const { clusters, supercluster } = useSupercluster({
		points,
		bounds: [
			bounds.getSouthWest().lng,
			bounds.getSouthWest().lat,
			bounds.getNorthEast().lng,
			bounds.getNorthEast().lat,
		],
		zoom,
		options: { radius: 50, maxZoom: 16 },
	});

	return (
		<>
			{clusters.map((cluster) => {
				const [lng, lat] = cluster.geometry.coordinates;
				const { cluster: isCluster, point_count: pointCount } =
					cluster.properties;

				if (isCluster) {
					return (
						<MapCluster
							key={`cluster-${cluster.id}`}
							lat={lat}
							lng={lng}
							pointCount={pointCount}
							totalCount={points.length}
							onClick={(e) => {
								if (!supercluster) {
									return;
								}

								const expansionZoom = Math.min(
									supercluster.getClusterExpansionZoom(Number(cluster.id)),
									17
								);
								map.setView(e.latlng, expansionZoom, {
									animate: true,
								});
							}}
						/>
					);
				}

				return (
					<MapPlace
						key={`place-${cluster.properties.place.id}`}
						{...cluster.properties}
					/>
				);
			})}
		</>
	);
}
