import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import type { BarType } from "../../types/common";

export function Bar({ href, image }: BarType) {
	const { url, alternativeText } = image.data.attributes;

	return (
		<div className="w-[300px] md:w-[220px] h-[96px]">
			<a
				href={href}
				className="group inline-block w-full h-full relative rounded-lg bg-white group shadow-[0_6px_12px_0_#00000029] overflow-hidden"
			>
				<img
					src={getStrapiImageUrl(url)}
					alt={alternativeText}
					className="absolute top-0 left-0 w-full h-full p-2 object-contain group-hover:scale-105 duration-200"
				/>
			</a>
		</div>
	);
}
