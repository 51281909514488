import { useStrapiFetch } from "../../hooks/use-strapi-fetch";
import type { SubsidesType } from "../../types/common";
import { useTranslation } from "react-i18next";
import { getStrapiImageUrl } from "../../lib/get-strapi-image-url";
import { Markdown } from "../../components/common/markdown";

export function SubsidesPage() {
	const { t, i18n } = useTranslation();
	const { data } = useStrapiFetch<SubsidesType>("/api/subsides?populate=logo");

	if (!data) {
		return null;
	}

	const { logo, contentPL, contentEN } = data.attributes;
	const { url, alternativeText } = logo.data.attributes;

	i18n.addResourceBundle(
		"pl",
		"translation",
		{ subsides: { content: contentPL } },
		true,
		true
	);

	i18n.addResourceBundle(
		"en",
		"translation",
		{ subsides: { content: contentEN } },
		true,
		true
	);

	return (
		<>
			<div className="w-full max-w-[1200px] mx-auto px-[15px] pt-7 md:pt-9 relative">
				<h1 className="font-semibold text-[#002969] text-xl sm:text-2xl md:text-3xl text-center">
					{t("subsides.title")}
				</h1>
			</div>

			<main>
				<div className="relative w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9">
					<img
						src={getStrapiImageUrl(url)}
						alt={alternativeText}
						className="object-contain"
					/>

					<Markdown className="mt-4 max-w-[1000px] mx-auto">
						{t("subsides.content")}
					</Markdown>
				</div>
			</main>
		</>
	);
}
