import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import type { NewType } from "../../types/common";
import { New } from "./new";
import type { StrapiReturn } from "../../types/strapi-return";
import { LatestNew } from "./latestNew";

export function News({ data }: { data?: StrapiReturn<NewType[]> }) {
	const { t, i18n } = useTranslation();

	if (!data) {
		return null;
	}

	data.forEach((n) => {
		const { titlePL, titleEN, bodyEN, bodyPL } = n.attributes;

		i18n.addResourceBundle(
			"pl",
			"translation",
			{ news: { [n.id]: { title: titlePL, body: bodyPL } } },
			true,
			true
		);

		i18n.addResourceBundle(
			"en",
			"translation",
			{ news: { [n.id]: { title: titleEN, body: bodyEN } } },
			true,
			true
		);
	});

	return (
		<div
			id="aktualnosci"
			className="w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9 flex flex-col gap-6 md:gap-9"
		>
			<LatestNew data={data[0]} />

			<h2 className="font-semibold text-[#002969] text-xl sm:text-2xl md:text-3xl text-center">
				{t("news.title")}
			</h2>

			<div className="flex flex-wrap justify-center gap-4 md:gap-8">
				{data.slice(1).map((n) => (
					<New key={`aktualnosci-${n.id}`} data={n} />
				))}
			</div>

			<div className="flex justify-center">
				<NavLink
					to="/aktualnosci"
					className="px-8 py-4 bg-[#E03549] hover:opacity-90 transition-opacity font-semibold text-white shadow-[0_6px_12px_0_#00000029] rounded-full block"
				>
					{t("news.more")}
				</NavLink>
			</div>
		</div>
	);
}
