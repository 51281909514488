import { useTranslation } from "react-i18next";
import { BarType } from "../../types/common";
import { Bar } from "./bar";
import type { StrapiReturn } from "../../types/strapi-return";

export function Bars({ data }: { data?: StrapiReturn<BarType[]> }) {
	const { t } = useTranslation();

	if (!data) {
		return null;
	}

	return (
		<div className="w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9">
			<div className="px-7 md:px-6 py-12 md:py-10 relative overflow-hidden">
				<div className="absolute top-0 left-0 w-full h-full -z-20">
					<img
						src="/theme/assets/mleko.png"
						alt="mleko"
						className="absolute top-0 left-0 w-full h-full object-cover rounded-3xl"
					/>
				</div>

				<div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-2/3 md:w-1/2 h-full -z-10">
					<div className="absolute bottom-0 left-0 w-full h-0 pb-[48%]">
						<img
							src="/theme/assets/talerz.png"
							alt="talerz"
							className="absolute top-0 left-0 w-full h-full object-contain"
						/>
					</div>
				</div>

				<h2 className="text-center text-xl md:text-2xl font-semibold text-white">
					{t("bars.title")}
				</h2>

				<div className="flex flex-wrap gap-y-3 md:gap-y-8 gap-x-20 justify-center mt-7 md:mt-9">
					{data.map(({ attributes }, id) => (
						<Bar key={`${id}-bar`} {...attributes} />
					))}
				</div>
			</div>
		</div>
	);
}
