import { Marker, Popup } from "react-leaflet";
import leaflet from "leaflet";
import { getBrandLogoUrl } from "../../lib/get-brand-logo-url";
import type {
	BrandPlace,
	BrandType,
	PlaceWorkTime,
} from "../../types/common";
import moment from "moment";
import "moment/locale/pl";
import { useTranslation } from "react-i18next";

function getIcon(src: string): leaflet.Icon {
	return new leaflet.Icon({
		iconUrl: src,
		iconRetinaUrl: src,
		iconSize: new leaflet.Point(50, 50),
		className: "object-contain",
	});
}

function mergePlaceWorkTime(workTime: PlaceWorkTime[], lang: string) {
	const groups: { [key: number]: PlaceWorkTime[] } = {};

	let group = 0;
	for (let i = 0; i < workTime.length - 1; i++) {
		const current = workTime[i];
		const next = workTime[i + 1];

		if (!groups[group]) {
			groups[group] = [];
		}
		groups[group].push(current);

		if (i === workTime.length - 2) {
			groups[group].push(next);
			break;
		}

		if (
			current.startOpen === next.startOpen &&
			current.endOpen === next.endOpen
		) {
			continue;
		}

		group++;
	}

	const merged: string[] = [];
	for (const key in groups) {
		const group = groups[key];

		const open = moment(group[0].startOpen, "HH:mm:ss").format("HH:mm");
		const close = moment(group[0].endOpen, "HH:mm:ss").format("HH:mm");

		if (group.length === 1) {
			merged.push(`${moment(group[0].dayInWeek % 7, "d")
				.locale(lang)
				.format("ddd")}. 
			${open} - ${close}`);
			continue;
		}

		const minDayInWeek = Math.min(...group.map((item) => item.dayInWeek));
		const maxDayInWeek = Math.max(...group.map((item) => item.dayInWeek));

		merged.push(`${moment(minDayInWeek % 7, "d")
			.locale(lang)
			.format("ddd")}. - 
			${moment(maxDayInWeek % 7, "d")
				.locale(lang)
				.format("ddd")}. 
		${open} - ${close}`);
	}
	return merged;
}

export function MapPlace({
	brand,
	place,
}: {
	brand: BrandType;
	place: BrandPlace;
}) {
	const { t, i18n } = useTranslation();

	return (
		<Marker
			icon={getIcon(getBrandLogoUrl(place.logo))}
			position={[place.config.geo.lat, place.config.geo.lon]}
			zIndexOffset={0}
		>
			<Popup autoPan={false}>
				<p>
					<span className="font-bold text-lg">{brand.name}</span>
					<br />
				</p>

				<p>
					{`${place.prefStreet} ${place.street} ${place.streetNumber}, ${place.city} ${place.postCode}`}
					{!!place.config.phone && (
						<>
							<br />
							<a href={`tel:${place.config.phone}`} className="!text-black">
								{place.config.phone}
							</a>
						</>
					)}
					<br />
				</p>

				<p>
					<span className="font-bold">{t("map.openHours")}</span>
					<br />
					{mergePlaceWorkTime(place.placesWorkTime, i18n.language).map(
						(item, index) => (
							<span key={`place-${place.id}-time-${index}`}>
								{item}
								<br />
							</span>
						)
					)}
				</p>
			</Popup>
		</Marker>
	);
}
