import { useTranslation } from "react-i18next";
import { CareerForm } from "./career-form";
import type { CareerType } from "../../types/common";
import { Markdown } from "../common/markdown";
import type { StrapiReturn } from "../../types/strapi-return";

export function Career({ data }: { data?: StrapiReturn<CareerType> }) {
	const { t, i18n } = useTranslation();

	if (!data) {
		return null;
	}

	const { bodyPL, bodyEN } = data.attributes;

	i18n.addResourceBundle(
		"pl",
		"translation",
		{ career: { body: bodyPL } },
		true,
		true
	);
	i18n.addResourceBundle(
		"en",
		"translation",
		{ career: { body: bodyEN } },
		true,
		true
	);

	return (
		<div
			id="praca"
			className="w-full max-w-[1200px] mx-auto px-[15px] py-7 md:py-9"
		>
			<h2 className="font-semibold text-[#002969] text-xl sm:text-2xl md:text-3xl text-center">
				{t("career.title")}
			</h2>

			<div className="flex flex-col md:flex-row gap-8 mt-6 md:mt-9">
				<div className="md:order-2 flex-1">
					<Markdown>{t("career.body")}</Markdown>
				</div>

				<div className="md:order-1 flex-1">
					<CareerForm />
				</div>
			</div>
		</div>
	);
}
