import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
	en: {
		translation: {
			error: { title: "Error occurred", reload: "Reload" },
			notFound: { text: "Page not found", button: "Go back" },
			navLinks: {},
			subsides: {
				title: "Subsidies",
			},
			news: {
				title: "News",
				read: "Read more",
				more: "Read more",
				goBack: "Back to articles",
			},
			bars: {
				title: "Choose your milk bar:",
			},
			map: {
				openHours: "Open hours:",
			},
			career: {
				title: "Career",
				form: {
					fullname: "Full name",
					email: "Email",
					cv: "Add CV",
					cvChange: "Change CV",
					position: {
						label: "Position",
						customerService: "Customer Service",
						chef: "Chef",
						kitchenSupport: "Kitchen Support",
						dishwasher: "Dishwasher",
					},
					rodo: `I agree to the processing of my personal data by VEGA FOOD Sp. z o. o. 
					for the purpose of conducting recruitment for the position I am applying for.`,
					submit: "Submit",
					onSubmit: "Submitting...",
					fullnameError: "Full name must be at least 4 characters.",
					emailError: "This is not a valid email.",
					cvError: {
						required: "CV is required.",
						size: "Max image size is {{size}}.",
						type: "Only PDF and images are allowed.",
					},
					rodoError: "Consent is required.",
					submitError: "An error occurred while submitting the form.",
					onSuccess: "Thank you for applying for the position.",
				},
			},
		},
	},
	pl: {
		translation: {
			error: { title: "Wystąpił błąd", reload: "Odśwież" },
			notFound: { text: "Strona nie znaleziona", button: "Wróć" },
			navLinks: {},
			subsides: {
				title: "Dotacje",
			},
			news: {
				title: "Aktualności",
				read: "Czytaj więcej",
				more: "Zobacz więcej",
				goBack: "Wróć do artykułów",
			},
			bars: {
				title: "Wybierz swój bar mleczny:",
			},
			map: {
				openHours: "Godziny otwarcia:",
			},
			career: {
				title: "Praca",
				form: {
					fullname: "Imię i nazwisko",
					email: "Email",
					cv: "Dodaj CV",
					cvChange: "Zmień CV",
					position: {
						label: "Stanowisko",
						customerService: "Obsługa Klienta",
						chef: "Kucharz",
						kitchenSupport: "Pomoc Kuchenna",
						dishwasher: "Zmywak",
					},
					rodo: `Wyrazam zgode na przetwarzanie moich danych osobowych przez VEGA FOOD Sp. z o. o.
					w celu prowadzenia rekrutacji na aplikowane przeze mnie stanowisko.`,
					submit: "Wyślij",
					onSubmit: "Wysyłanie...",
					fullnameError: "Imię i nazwisko musi mieć co najmniej 4 znaki.",
					emailError: "To nie jest poprawny email.",
					cvError: {
						required: "CV jest wymagane.",
						size: "Maksymalny rozmiar obrazu to {{size}}.",
						type: "Dozwolone są tylko pliki PDF i obrazy.",
					},
					rodoError: "Zgoda jest wymagana.",
					submitError: "Wystąpił błąd podczas wysyłania formularza.",
					onSuccess: "Dziękujemy za aplikowanie na dane stanowisko.",
				},
			},
		},
	},
};

function getInitialLang() {
	if (localStorage.getItem("lang") === "en") {
		return "en";
	}

	return "pl";
}

i18n.use(initReactI18next).init({
	resources,
	lng: getInitialLang(),
	fallbackLng: "pl",
});

export default i18n;
